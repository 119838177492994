export const BASE_URL = process.env.REACT_APP_API_ENDPOINT
  ? process.env.REACT_APP_API_ENDPOINT
  : "https://api-allocation-dev.iaproducts.ai/api/"
  // : "https://api-allocation-prod.iaproducts.ai/api/";
  // :"http://127.0.0.1:8000/api/"
export const SIMULATION_URL = process.env.REACT_APP_API_SIMULATION
  ? process.env.REACT_APP_API_SIMULATION
  : "https://tommy-assortment-simulation-dev.iaproducts.ai/api/";
// export const BASE_URL = "https://puma-prod.iaproducts.ai/api/";
// export const SIMULATION_URL = "https://puma-simulation-prod.iaproducts.ai/api/";

export const REFRESH_API = "";

export const SET_USER_PREFERENCE = BASE_URL + "product/strategy/save-user-department"
export const GET_USER_PREFERENCE = BASE_URL + "product/strategy/get-user-department"
export const GET_PLAN_DETAILS = BASE_URL + "product/plan/get/";
export const CREATE_PLAN = BASE_URL + "product/plan/create/";
export const EDIT_PLAN = BASE_URL + "product/plan/edit/";
export const DELETE_PLAN = BASE_URL + "product/plan/delete/";
export const GET_STORE_GROUP = BASE_URL + "product/stores/get-group-dept";
export const GET_STORES_STORE_GROUP =
  BASE_URL + "product/stores/get-group-stores-dept";
export const CREATE_STORE_GROUP = BASE_URL + "product/stores/create-group-dept";
export const EDIT_STORE_GROUP = BASE_URL + "product/stores/edit-group";
export const DELETE_STORE_GROUP = BASE_URL + "product/stores/delete-group-dept"
export const SAVE_CLUSTER_BUCKET = BASE_URL + "product/cluster/final-save/";
export const RUN_CLUSTER_GROUP = SIMULATION_URL + "significance_score/";
export const GET_SIGNIFICANT_SCORE = BASE_URL + "product/cluster/get-sig-score";
export const RUN_INTELLIGENT_CLUSTER = SIMULATION_URL + "cluster/";
export const GET_BUCKET_DETAILS =
  BASE_URL + "product/cluster/get-intelligent-cluster";
export const GET_STORE = BASE_URL + "product/stores/get";
export const GET_STORE_FILTER = BASE_URL + "product/stores/get-filters";
export const GET_STORE_LEVEL_FILTER = BASE_URL + "product/stores/get-store-filters";
export const CSV_DOWNLOADS = BASE_URL + "product/downloads/";
export const GET_PLAN_FILTERS = BASE_URL + "product/filter/get/";
export const FETCH_CLUSTER_BREAKDOWN =
  BASE_URL + "product/cluster/clusters-grade/";
export const PERFORMANCE_CHART_DATA = BASE_URL + "product/cluster/performance/";
export const GET_SWAP_STORE = BASE_URL + "product/cluster/get-cluster-stores";
export const SAVE_SWAP_STORE =
  BASE_URL + "product/cluster/update-cluster-stores";
export const FETCH_BUDGET_DEPT_DATA =
  BASE_URL + "product/optimize/get-plan-budget";
export const SAVE_BUDGET_DEPT_DATA =
  BASE_URL + "product/plan-budget/create-plan-budget";
export const OPTIMIZE_CLASS = SIMULATION_URL + "cluster_cls_optimization/";
export const OPTIMIZE_CLUSTER = SIMULATION_URL + "cluster_optimization/";
export const GET_OPTIMIZED_CLUSTER =
  BASE_URL + "product/optimize/get-cluster-plan";
export const OPTIMIZE_PRODUCT = BASE_URL + "product/optimize/product/";
export const UPDATE_CLASSLEVEL_ROW =
  BASE_URL + "product/optimize/update-optimized-class";
export const GET_CLASS_LIST =
  BASE_URL + "product/optimize/get-class-optimization";
export const CREATE_NEW_CLASS = SIMULATION_URL + "new_class/";
export const SAVE_BUDGET_CLUSTER_DATA =
  BASE_URL + "product/optimize/cluster-level-plan";
export const NAVIGATE_PLAN_STEP = BASE_URL + "product/plan/planstep/";
export const ATTRIBUTE_CHART_DATA =
  BASE_URL + "product/cluster/attribute-grade/";
export const FETCH_OPTIMIZATION_CONSTRAINT =
  SIMULATION_URL + "Cluster_level_APS_ST";
export const FETCH_DEPTH_CHOICE =
  SIMULATION_URL + "Cluster_level_Depth_Choice_Count";

export const UPDATE_OPTIMIZATION_CONSTRAINT =
  BASE_URL + "product/dept-choice/update-cluster-aps-st/";

export const SAVE_DEPTH_AND_CHOICE =
  BASE_URL + "product/dept-choice/cluster-depth-choice/";

export const CHECK_UNIQ_STOREGROUPNAME =
  BASE_URL + "product/stores/get-stores-group";

export const GET_FISCAL_DATE = BASE_URL + "product/date/get-fiscal-date/";

export const GET_WEDGE_OPTIMIZE_PLAN =
  BASE_URL + "product/wedge/get-plan-optimization";

export const UPDATE_WEDGE_OPTIMIZE_PLAN_DATA =
  BASE_URL + "product/wedge/update-plan-optimization";

export const FETCH_DROP = BASE_URL + "product/wedge/get-plan-drop";

export const UPDATE_DROP = BASE_URL + "product/wedge/update-drop";

export const GET_WEDGE_TABLE_DATA =
  SIMULATION_URL + "Wedge_Calculation_attribute_reco";

export const GET_DEPTH_MULTIPLIER_DATA =
  BASE_URL + "product/wedge/depth-multipler";

export const GET_PLAN_CLUST_LEVEL = BASE_URL + "product/plan/get-plan-metrics";

export const SIZE_SIMULATION_URL = SIMULATION_URL + "size_review";

export const GET_SIZE_DETAILS = BASE_URL + "product/finalize/get-size-details";

export const GRADE_SIMULATION_URL = SIMULATION_URL + "size_attributes";

export const GET_GRADE_DETAILS =
  BASE_URL + "product/finalize/get-grade-details";

export const GET_GRADE_ATTR_DETAILS =
  BASE_URL + "product/finalize/get-attribute-grade-details";

export const GET_WEDGE_TABLE_DATA_BE = BASE_URL + "product/wedge/get-wedge";

export const VALIDATE_PLAN = BASE_URL + "product/plan/validate-plan";

export const UPDATE_WEDGE_TABLE = BASE_URL + "product/wedge/update-wedge-plan";

export const GET_WEDGE_FILTERS =
  BASE_URL + "product/wedge/get-plan-wedge-metrics";

export const STRATEGY_TABLE_API = "product/strategy/get-allocate";
export const FILTER_DATA_API = "product/strategy/get-filter";
export const STRATEGY_TABLE_DROPDOWN = "product/strategy/get-dropdown";
export const STRATEGY_TABLE_SG_MIN_STOCK =
  "product/strategy/get-store-group-minstock";

export const STORE_TABLE_DATA =
  "simulation/allocation-strategy-constraints-pp-props";
export const DC_TABLE_DATA = "simulation/allocation-strategy-dc-level-metrics";
export const EDIT_TABLE_ADA = BASE_URL + "product/ada/edit_data_ada";
export const CONFIRM_DATA_ADA = BASE_URL + "product/ada/confirm_data_ada"
export const REVIEW_ADA = "product/ada/review_forecast_ca"

export const SAVE_CHANGES_CA_VISUAL = BASE_URL + "product/ada/save_changes_ca_visual"

export const CONSTRAINTS_FILTER_DATA_API =
  BASE_URL + "product/constraint/get-filter";
export const CONSTRAINTS_TABLE_DATA_API = BASE_URL + "product/constraint/get";
export const CONSTRAINTS_POPUP_DATA_API =
  BASE_URL + "product/constraint/get-popup";
export const CONSTRAINTS_UPDATE_TABLEDATA_API =
  BASE_URL + "product/constraint/update";

export const PRODUCTPROFILE_FILTER_DATA_API =
  BASE_URL + "product/productprofile/get-filter";
export const PRODUCTPROFILE_TABLE_DATA_API =
  BASE_URL + "product/productprofile/get";
export const STORES_TABLE_DATA_API =
  BASE_URL + "product/productprofile/get-contribution";
export const PRODUCTPROFILE_CREATEFILTER_DATA_API =
  BASE_URL + "product/productprofile/create-filter";
export const PRODUCTLIST_CONTRIBUTION_DATA_API =
  BASE_URL + "simulation/product-list-contribution";
export const PROFILED_PRODUCT_DATA_API =
  BASE_URL + "simulation/penetration-percentage-calculation";
export const SAVE_PROFILED_DATA = BASE_URL + "product/productprofile/update";
export const FETCH_SAVED_DATA = BASE_URL + "product/productprofile/get-update";
export const DELETE_PRODUCT_PROFILE =
  BASE_URL + "product/productprofile/delete";

export const DASHBOARD_FILTER_DATA_API =
  BASE_URL + "product/dashboard/get-filter";
export const DASHBOARD_CHARTS_DATA_API = BASE_URL + "product/dashboard/get";

export const DASHBOARD_STYLE_INVENTORY_DATA_API = BASE_URL + "product/dashboard/get-style-inventory"

export const GET_RECOMMENDED_ACTIONS_DATA_API = BASE_URL + "product/dashboard/get-recommended-data";

export const GET_STYLE_INV_POPUP_DATA =
  BASE_URL + "product/dashboard/get-style-popup";

export const GET_USER_DATA =
  BASE_URL + "product/usermanagement/get-users";

export const GET_FORM_DATA =
  BASE_URL + "product/usermanagement/get-update"

export const CREATE_USER =
  BASE_URL + "product/usermanagement/create"

export const EDIT_USER =
  BASE_URL + "product/usermanagement/edit"

export const DELETE_USER =
  BASE_URL + "product/usermanagement/delete-user"

export const LOGIN_API =
  BASE_URL + "product/usermanagement/login"

export const JWT_AFTER_SSO_API = BASE_URL + "product/usermanagement/get-user-column-preference"

export const UPDATE_TOKEN =
  BASE_URL + "product/usermanagement/update-token"
  
export const GET_REVIEW_RECOMMEND_API = BASE_URL + "product/dashboard/review-recommend";

export const CREATE_ALLOCATION = "simulation/create-allocation"

export const OPTIMIZATION_ALGO_INPUT_DATA = "simulation/optimization-algo-input-data"

export const UPDATE_RESERVED_QTY = "product/strategy/update-reserved-quantity"

export const GET_STORE_VIEW = BASE_URL + "product/finalize/get-store-view"
export const GET_ARTICLE_STORE_VIEW = BASE_URL + "product/finalize/get-article-store-view"
export const GET_PRODUCT_VIEW = BASE_URL + "product/finalize/get-product-view"
export const GET_PRODUCT_STORE_VIEW = BASE_URL + "product/finalize/get-product-store-view"
export const EDIT_ALLOCATED_UNITS = BASE_URL + "product/finalize/update-allocated-units"
export const ALLOCATION_PO_CREATION = BASE_URL + "simulation/allocation-po-creation"
export const ALLOCATION_PO_CREATION_BEFORE_FINALIZE = BASE_URL + "product/finalize/export-allocation-info"
export const POST_ALLOCCATION_PO = BASE_URL + "simulation/pastallocation-po-creation"
export const GET_ALLOCATION_PLANS = BASE_URL + "product/viewAllocation/get-allocation-plans"
export const UPD_RES_QTY = BASE_URL + "product/finalize/update-allocated-quantity"
export const GET_PACK_DETAILS_API = BASE_URL + "product/finalize/pack-details"
export const UPLOAD_FILE = BASE_URL + "product/finalize/upload-file"
export const UPDATE_PACKS_EACHES_FOR_STORES = BASE_URL + "product/finalize/update-allocated-units-multi"
export const UPDATE_FINALIZE_INFO = BASE_URL + "product/finalize/update-finalize-info"
export const GET_PACKS_FOR_BULK_UPDATE_API = BASE_URL + "product/finalize/bulk-edit-packs"
export const DISABLE_EDIT = BASE_URL + "product/finalize/disable-edit"

export const SAVE = "product/strategy/save"

export const GET_STORE_GROUP_MIN_STOCK = "product/strategy/get-store-group-minstock"
export const GET_DC_DATA = "product/strategy/get-dc-data"

export const GET_SIZE_DC = "product/strategy/get-size-dc"

export const GET_APS = "product/strategy/get-aps"

export const GET_ADA_HIERARCHY_API_ALLOCATION = BASE_URL + "product/ada/get-lower-heirarchy-forecasts-store-list"


/**Ada-VisualStandAlone Constants */
export const ADAVISUAL_FILTER_DATA_API = BASE_URL + "product/ada/get-ada-filter-data";
export const GET_ADA_ALLOCATION_API = BASE_URL + "product/ada/get-ada-data-allocation";
export const GET_ADA_STANDALONE_API = BASE_URL + "product/ada/get-ada-data-standalone";
export const GET_ADA_DRIVERS_API = BASE_URL + "product/ada/get-forecasts-drivers";
export const GET_ADA_HIERARCHY_API = BASE_URL + "product/ada/get-lower-heirarchy-forecasts";
export const ADA_REVIEW_API = BASE_URL + "product/ada/review-ada";

export const SAVE_DRAFT = "product/strategy/save-draft"
export const GET_DRAFTS = "product/strategy/get-draft"
export const DELETE_DRAFT = "product/strategy/delete-draft"
export const VALIDATE_DRAFT_NAME = "product/strategy/validate-plan-name"

export const GET_REPORTING_FILTERS = BASE_URL + "product/reporting/get-reporting-filters"
export const GET_REPORTING = BASE_URL + "product/reporting/get-reporting"

export const GET_STORE_STATUS = BASE_URL + "product/stores/store-status"


export const GET_PRODUCT_CONFIG_FILTERS = BASE_URL + "product/productconfiguration/get-filters"
export const GET_PRODUCT_CONFIGURATION = BASE_URL + "product/productconfiguration/product-configuration"
export const GET_ARTICLE_MAPPING = BASE_URL + "product/productconfiguration/article-mapping"
export const GET_STORE_GROUP_MAPPING = BASE_URL + "product/productconfiguration/store-group-mapping"
export const GET_PP_MAPPING = BASE_URL + "product/productconfiguration/product-profile-mapping"
export const GET_DC_MAPPING = BASE_URL + "product/productconfiguration/dc"
export const CREATE_PRODUCT_CONFIG = BASE_URL + "product/productconfiguration/product-configuration"

export const GET_TRANSACTION = BASE_URL + "product/dashboard/refresh-date"

export const SSE_CREATE_ALLOCATON = BASE_URL + "simulation/allocate-stream"
//Lost Sales Report API's
export const GET_LOST_SALES_REPORT_FILTERS = BASE_URL + "product/reporting/get-lost-sales/filter";
export const GET_LOST_SALES_REPORT_ALL = BASE_URL + "product/reporting/get-lost-sales/all";
export const GET_LOST_SALES_REPORT_DETAILS_TABLE = BASE_URL + "product/reporting/get-lost-sales/table";

export const GET_EXCESS_TABLE_DATA = BASE_URL + "product/reporting/get_excess_inventory_tabular_data"
export const GET_EXCESS_GRAPH_DATA = BASE_URL + "product/reporting/get_excess_inventory_graph_data"

export const GET_DEEP_DIVE_FILTERS = BASE_URL + "product/reporting/get-allocate-deep-dive/filter"
export const GET_DEEP_DIVE_DATA = BASE_URL + "product/reporting/get-allocate-deep-dive/table"
export const GET_EXCESS_FILTERS = BASE_URL + "product/reporting/get_excess_inventory_filter_data"

export const GET_DAILY_SUMMARY_FILTERS = BASE_URL + "product/reporting/daily-allocation/filter"
export const GET_DAILY_SUMMARY_DATA = BASE_URL + "product/reporting/daily-allocation/all"
export const GET_DAILY_SUMMARY_SKU_DATA = BASE_URL + "product/reporting/daily-allocation/style"

//Strategy Configuration Api's
export const GET_STRATEGY_CONFIGURATION_FILTERS_DATA = BASE_URL + "product/strategyconfiguration/get-filters";
export const GET_STRATEGY_CONFIGURATION_TABLE_DATA = BASE_URL + "product/strategyconfiguration/get-table";
export const GET_STRATEGY_CONFIGURATION_DATA = BASE_URL + "product/strategyconfiguration/get-config";
export const SAVE_STRATEGY_CONFIGURATION_DATA = BASE_URL + "product/strategyconfiguration/save-config"
export const DELETE_STRATEGY_CONFIG_API = BASE_URL + "product/strategyconfiguration/delete-configs"
//PO/ASN Api's
export const GET_PO_ASN_DETAILS = BASE_URL + "po-asn/get-details"
export const REDIRECT_SSO_LOGIN = BASE_URL + "saml/login"

//To store user preference table columns
export const SAVE_USER_PREFERENCE_COLUMNS = BASE_URL + "product/usermanagement/save-user-column-preference"

//Inventory Reservation API's
export const GET_INVENTORY_RESERVATION_FILTERS_DATA = BASE_URL + "product/inventory-reservation/get-filter";
export const GET_INVENTORY_RESERVATION_TABLE_DATA = BASE_URL + "product/inventory-reservation/get-details"
export const EDIT_INVENTORY_RESERVATION_TABLE_DATA = BASE_URL + "product/inventory-reservation/update-reserve-quantity"

//New Store Setup
export const GET_STORELIST_DETAILS_CONFIG = BASE_URL + "product/new-store/store-list";

export const GET_DEMAND_DATA = BASE_URL + "product/new-store/style-constraints"
export const GET_DEMAND_ADD_PRODUCTS_DATA = BASE_URL + "product/new-store/style-constraints-add"
export const GET_DC_SOURCE_DETAILS = BASE_URL + "product/new-store/dc-list";
export const UPDATE_DC_SOURCE_EDITED_TIME = BASE_URL + "product/new-store/update-lead-time";
export const GET_NEW_STORE_ALLOCATIONS = BASE_URL + "product/dashboard/review-recommend";
export const SAVE_NEW_STORE_ALLOCATION = BASE_URL + "product/new-store/new-store-allocate";
export const GET_REVIEW_ALLOCATION_DETAILS = BASE_URL + "product/new-store/allocation-details";
export const REVIEW_NEW_STORE_ALLOCATION = BASE_URL + "simulation/create-allocation-new-store";
export const GET_ARTICLE_ALLOCATION = BASE_URL + "product/new-store/get-allocated-total";
export const UPDATE_ARTICLE_ALLOCATION = BASE_URL + "product/new-store/update-allocated-total";
export const GET_SISTER_STORE_FILTERS = BASE_URL + "product/new-store/sister-store-filters"
export const SAVE_NEW_STORE_INFO = BASE_URL + "product/new-store/save-new-store-info"
export const UPSERT_NEW_STORE_DETAILS = BASE_URL + "product/new-store/upsert-details"
export const GET_NEW_RESERVED_STORE_LIST = BASE_URL + "product/new-store/new-reserved-store-list"
export const GET_NEW_RESERVED_ARTICLE_LIST = BASE_URL + "product/new-store/new-reserved-article-list"
export const GET_RESERVED_ARTICLES_INFO = BASE_URL + "product/new-store/reserved-articles-info"
export const DELETE_NEW_STORE = BASE_URL + "product/new-store/delete-new-store"
export const GET_EDIT_RESERVED_DETAILS = BASE_URL + "product/new-store/edit-reserved"
export const APPROVE_NEW_STORE_ARTICLES = BASE_URL + "product/new-store/approve-article"
export const GET_NEW_STORE_ALLOCATED_ARTICLES = BASE_URL + "product/new-store/allocated-articles"
export const RELEASE_NEW_STORE_ARTICLES = BASE_URL + "product/new-store/release-article"
export const GET_NEW_STORE_SIZE_CURVE = BASE_URL + "product/new-store/size-curve"
export const GET_AUTO_ALLOCATION_DETAILS = BASE_URL + "product/finalize/auto-allocation-details"
export const GET_STORE_GRADES = BASE_URL + "product/new-store/store-grades"
export const GET_NEW_STORE_PAST_ALLOCATIONS = BASE_URL + "product/new-store/past-allocations"

//Daily summary store view
export const GET_DAILY_SUMMARY_STORE_VIEW = BASE_URL + "product/reporting/daily-allocation/store/all"
export const GET_DAILY_SUMMARY_STORE_VIEW_DETAILS = BASE_URL + "product/reporting/daily-allocation/store/table2"
export const GET_DAILY_SUMMARY_STORE_VIEW_ARTICLE_DETAILS = BASE_URL + "product/reporting/daily-allocation/store/table3"

export const GET_PRODUCT_CONFIG_STYLE_MAPPING = BASE_URL + "product/productconfiguration/related-articles"

export const SAVE_STORE_DATA = BASE_URL + "product/finalize/save-dates"
export const UPDATE_AUTO_ALLOCATION_REQUIRED = BASE_URL + "product/productconfiguration/auto-allocation-required"

export const DEFAULT_CONSTRAINTS_FILTER_DATA_API = BASE_URL + "product/constraint/default/get-filter";

export const DEFAULT_CONSTRAINTS_TABLE_DATA_API = BASE_URL + "product/constraint/default/get";

export const CONSTRAINTS_UPDATE_DEFAULT_DATA_API = BASE_URL + "product/constraint/default/update";

//To get DC list in store config
export const GET_STORE_CONFIG_DC_LIST = BASE_URL + "product/stores/dc-list"

export const GET_ADA_STORE_HIERARCHY_API = BASE_URL + "product/ada/get-lower-heirarchy-forecasts-store-level";

export const GET_STORE_CAPACITY_DATA = BASE_URL + "product/finalize/get-store-capacity";

//End point to save allocation as Work In Progress
export const SAVE_WIP_ALLOCATION = BASE_URL + "product/finalize/save-as-wip-allocation";

//End point to get the manual Work In Progress allocations details from dashboard review recommendations 
export const GET_MANUAL_WIP_ALLOCATIONS = BASE_URL + "product/finalize/wip-manual-allocation-details"

